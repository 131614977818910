import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "container"];

  connect() {
    // const icon = document.createElement("i");
    // icon.classList.add("far");
    // icon.classList.add("fa-eye");
    // const container = document.createElement("div");
    // container.classList.add("icon-container");
    // container.classList.add("cursor-pointer");
    // container.append(icon);

    this.containerTarget.addEventListener(
      "click",
      function (e) {
        this.updateInput();
      }.bind(this)
    );
  }

  updateInput() {
    const input = this.inputTarget;
    const container = this.containerTarget;
    container.querySelector(".icon-container svg").remove();
    const inputType = input.getAttribute("type");
    const newIcon = document.createElement("i");
    newIcon.classList.add("far");

    if (inputType === "text") {
      newIcon.classList.add("fa-eye");
      input.setAttribute("type", "password");
    } else {
      newIcon.classList.add("fa-eye-slash");
      input.setAttribute("type", "text");
    }
    container.append(newIcon);
  }
}
