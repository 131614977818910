import fontawesome from "@fortawesome/fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/fontawesome-free-brands";
import regularIcons from "@fortawesome/fontawesome-free-regular";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // register icons we want to use in our app
  initialize() {
    // we can add all icons at once
    fontawesome.library.add(regularIcons);

    // or we can add icons individually
    fontawesome.library.add([faInstagram, faTwitter]);
  }

  // tell Font Awesome to convert icons every time the controller connects
  connect() {
    console.log(fontawesome);
    fontawesome.dom.i2svg();
  }
}
